<template>
  <div class='admin-dashboard-main-wrapper'>
    <div class='admin-dashboard-kyc-summary-with-filter-wrapper'>
      <div class='admin-dashboard-kyc-summary'>
        <div class="admin-dashboard-kyc-summary-text">KYC summary</div>
        <div class='kyc-summary-display-main-wrapper' style='margin-top: 20px'>
          <div class='kyc-summary-sub-wrapper' style='margin-right: 20px'>
            <div class='kyc-sub-wraper-text'>Invited</div>
            <div class='kyc-sub-wraper-count'>{{ eSigned }}</div>
            <div class='kyc-sub-wraper-filter'>
              <right-circle-outlined
                :style="{
                  fontSize: '23px',
                  marginLeft: '10px',
                  cursor: 'pointer',
                }"
              />
            </div>
          </div>
          <div class='kyc-summary-sub-wrapper'>
            <div class='kyc-sub-wraper-text'>Incomplete Form</div>
            <div class='kyc-sub-wraper-count'>{{ incompleteForm }}</div>
            <div class='kyc-sub-wraper-filter'>
              <right-circle-outlined
                :style="{
                  fontSize: '23px',
                  marginLeft: '10px',
                  cursor: 'pointer',
                }"
              />
            </div>
          </div>
        </div>
        <div class='kyc-summary-display-main-wrapper'>
          <div class='kyc-summary-sub-wrapper' style='margin-right: 20px'>
            <div class='kyc-sub-wraper-text'>Approval Pending</div>
            <div class='kyc-sub-wraper-count'>{{ approvalPending }}</div>
            <div class='kyc-sub-wraper-filter'>
              <right-circle-outlined
                :style="{
                  fontSize: '23px',
                  marginLeft: '10px',
                  cursor: 'pointer',
                }"
              />
            </div>
          </div>
          <div class='kyc-summary-sub-wrapper'>
            <div class='kyc-sub-wraper-text'>Approved</div>
            <div class='kyc-sub-wraper-count'>{{ approved }}</div>
            <div class='kyc-sub-wraper-filter'>
              <right-circle-outlined
                :style="{
                  fontSize: '23px',
                  marginLeft: '10px',
                  cursor: 'pointer',
                }"
              />
            </div>
          </div>
        </div>
        <div class='kyc-summary-display-main-wrapper'>
          <div class='kyc-summary-sub-wrapper' style='margin-right: 20px'>
            <div class='kyc-sub-wraper-text'>Rejected</div>
            <div class='kyc-sub-wraper-count'>{{ rejected }}</div>
            <div class='kyc-sub-wraper-filter'>
              <right-circle-outlined
                :style="{
                  fontSize: '23px',
                  marginLeft: '10px',
                  cursor: 'pointer',
                }"
              />
            </div>
          </div>
          <div class='kyc-summary-sub-wrapper'>
            <div class='kyc-sub-wraper-text'>Account Opened</div>
            <div class='kyc-sub-wraper-count'>{{ accountOpened }}</div>
            <div class='kyc-sub-wraper-filter'>
              <right-circle-outlined
                :style="{
                  fontSize: '23px',
                  marginLeft: '10px',
                  cursor: 'pointer',
                }"
              />
            </div>
          </div>
        </div>
      </div>
      <div class='admin-dashboard-submit-wrapper'>
        <div class='filter-from-and-to-date-wrapper'>
          <div style='width: 100%; margin-right: 20px'>
            <input
              style='
                background: #fff;
                border-radius: 6px;
                border: 1px solid #1b305b;
                height: 50px;
                width: 100%;
                padding: 10px;
              '
              class='pan-details-date-picker'
              @change='onChangeFromDate'
              type='date'
              id='fromDate'
              value=''
            />
          </div>
          <div style='width: 100%'>
            <input
              style='
                background: #fff;
                border-radius: 6px;
                border: 1px solid #1b305b;
                height: 50px;
                width: 100%;
                padding: 10px;
              '
              class='pan-details-date-picker'
              @change='onChangeToDate'
              type='date'
              id='toDate'
              value=''
            />
          </div>
        </div>
        <div style="margin-top:20px">
            <a-select
              class='admin-dashboard-select-field'
              v-model:value='status'
              placeholder='Status'
              mode='multiple'
              :options='statusOptions'
              style='text-align: initial;width:100%'
            ></a-select>
        </div>
        <div style="margin-top:20px">
            <a-input
              class='admin-dashboard-input-field'
              v-model:value='panNumber'
              placeholder='Enter PAN No.'
            ></a-input>
        </div>
        <div style="margin-top:20px">
            <a-input
              class='admin-dashboard-input-field'
              v-model:value='mobileNumber'
              placeholder='Enter Mobile No.'
            ></a-input>
        </div>
        <div style="margin-top:20px">
            <a-input
              class='admin-dashboard-input-field'
              v-model:value='rmId'
              placeholder='Enter RM ID.'
            ></a-input>
        </div>
        <div class='admin-filter-submit-btn-wrapper'>
              <a-button @click="onClickSubmit">Submit</a-button>
        </div>
      </div>
    </div>
    <div class="admin-dashboard-table-main-wrapper">
    <div>
      <span class="kyc-search-result-text">
        KYC Search Result</span> <span class="kyc-date-wrapper"></span>
    </div>
    <div class="admin-dashboard-table-wrapper">
    <a-table
    class="admin-dashboard-table"
      :columns="adminKycColumns"
      :data-source="adminKycData"
      :pagination="false"
      :rowKey="(record,index) => record.id"
      :customRow="customRow">
    </a-table>
    <div v-if="showViewMore" class='ria-backoffice-table-view-more-btn'>
      <a-button  @click="onClickViewMore"
      class='client-listing-view-more-btn'>View more</a-button>
    </div>

    </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { useRouter } from 'vue-router';
import { onMounted, ref, reactive } from 'vue';
import { RightCircleOutlined } from '@ant-design/icons-vue';
import { useStore } from 'vuex';
import services from '../../../services/apis';
import STATUS from "../../../constants/approvalStages";
import Utils from '../../../utils/index';

export default {
  components: {
    RightCircleOutlined,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const eSigned = ref(0);
    const rejected = ref(0);
    const accountOpened = ref(0);
    const approved = ref(0);
    const incompleteForm = ref(0);
    const approvalPending = ref(0);
    const adminKycData = reactive([]);
    const fromDate = ref('');
    const toDate = ref('');
    const mobileNumber = ref('');
    const panNumber = ref('');
    const rmId = ref('');
    const status = ref([]);
    const showViewMore = ref(true);
    let pageCount = 0;
    const onChangeFromDate = () => {
      const selectedFromDate = document.getElementById('fromDate').value;
      fromDate.value = selectedFromDate;
    };
    const onChangeToDate = () => {
      const selectedToDate = document.getElementById('toDate').value;
      toDate.value = selectedToDate;
    };
    const fetchDashboardStatistics = (data) => {
      delete data.pageNo
      services
        .fetchDashboardStatistics(data)
        .then((response) => {
          const data = response;
          eSigned.value = data.data.clients.invited ? data.data.clients.invited : 0;
          rejected.value = data.data.kycs.rejected ? data.data.kycs.rejected : 0;
          accountOpened.value = data.data.accounts.opened ? data.data.accounts.opened : 0;
          approved.value = data.data.kycs.approved ? data.data.kycs.approved : 0;
          incompleteForm.value = data.data.clients.incompleteForms ? data.data.clients.incompleteForms : 0;
          approvalPending.value = data.data.kycs.approvalPending ? data.data.kycs.approvalPending : 0;
        })
        .catch(() => {});
    };
    const onClickSubmit = () => {
      pageCount = 0;
      const data = {
        fromDate: fromDate.value,
        toDate: toDate.value,
        status: status.value,
        pan: panNumber.value,
        mobile: mobileNumber.value,
        rmId: rmId.value,
        pageNo: pageCount,
      };
      fetchDashboardStatistics(data);
      fetchDashboardKycs(data);
    }
    const onClickViewMore = () => {
      pageCount += 1;
      const data = {
        fromDate: fromDate.value,
        toDate: toDate.value,
        status: status.value,
        pan: panNumber.value,
        mobile: mobileNumber.value,
        rmId: rmId.value,
        pageNo: pageCount,
      };
      fetchDashboardKycs(data);
    };
    const fetchDashboardKycs = async (data) => {
      let emptyArray = [];
      if (pageCount > 0) {
        emptyArray = adminKycData;
      } else {
        adminKycData.splice(0);
      }
      let index = adminKycData.length;
      data.pageNo = pageCount;
      await services
        .fetchDashboardKycs(data)
        .then((response) => {
          const { data } = response;
          data.map((x) => {
            emptyArray.push({
              serialNumber: index += 1,
              date: x.date ? x.date: '-',
              eSignDate: x.eSignDate ? x.eSignDate : '-',
              formNumber: '-',
              branchCode: '-',
              rmCode: '-',
              formfilled: '-',
              clientCode: x.client.clientId ? x.client.clientId : '-',
              dataSource: '-',
              emailId: x.client.email ? x.client.email : '-',
              mobileNumber: x.client.mobile ? x.client.mobile : '-',
              id: x.id,
              clientName: x.client.name ? x.client.name : '-',
              panNumber: x.client.pan ? x.client.pan : '-',
              status: x.status ? Utils.clientStatus(x.status) : '-',
            });
            return emptyArray;
          });
          if (data.length < 10) {
            showViewMore.value = false;
          } else {
            showViewMore.value = true;
          }
        })
        .catch(() => {});
      Object.assign(adminKycData, emptyArray);
    };
    onMounted(() => {
      window.scrollTo(0, 0);
      fetchDashboardStatistics({});
      fetchDashboardKycs({});
    });
    const statusOptions = reactive([
      {
        value: STATUS.STATUS.INVITED,
        label: 'Invited',
      },
      {
        value: STATUS.STATUS.IN_PROGRESS,
        label: 'In progress',
      },
      {
        value: STATUS.STATUS.KYC_PENDING,
        label: 'KYC Pending',
      },
      {
        value: STATUS.STATUS.KYC_REJECTED,
        label: 'KYC Rejected',
      },
      {
        value: STATUS.STATUS.KYC_ACCEPTED,
        label: 'KYC Accepted',
      },
      {
        value: STATUS.STATUS.ACTIVE,
        label: 'Active',
      },
    ]);
    const reportOptions = reactive([
      {
        value: 'APPROVED',
        label: 'Approved',
      },
      {
        value: 'REJECTED',
        label: 'Rejected',
      },
    ]);
    const adminKycColumns = [
      {
        title: 'Sr',
        dataIndex: 'serialNumber',
      },
      {
        title: 'Client Name',
        dataIndex: 'clientName',
        // slots: { customRender: "clientName" },
      },
      {
        title: 'Mobile No',
        dataIndex: 'mobileNumber',
      },
      {
        title: 'Email ID',
        dataIndex: 'emailId',
      },
      {
        title: 'Date',
        dataIndex: 'date',
      },
      {
        title: 'Esign Date',
        dataIndex: 'eSignDate',
      },
      {
        title: 'Client Code',
        dataIndex: 'clientCode',
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Data Source',
        dataIndex: 'dataSource',
      },
      {
        title: 'RM Code',
        dataIndex: 'rmCode',
      },
      {
        title: 'Form No',
        dataIndex: 'formNumber',
      },
      {
        title: 'PAN No',
        dataIndex: 'panNumber',
      },
      {
        title: 'Branch Code',
        dataIndex: 'branchCode',
      },
      {
        title: 'Form Filled',
        dataIndex: 'formfilled',
      },
    ];
    const customRow = (record) => {
      return {
        onClick: () => {
          router.push(`/admin/kyc-approval/${record.id}`);
          store.dispatch('onboardingStore/updateClientId', record.id);
        }
      };
    };
    return {
      status,
      statusOptions,
      reportOptions,
      fetchDashboardStatistics,
      eSigned,
      rejected,
      accountOpened,
      approved,
      incompleteForm,
      approvalPending,
      adminKycColumns,
      adminKycData,
      onChangeFromDate,
      onChangeToDate,
      onClickSubmit,
      rmId,
      mobileNumber,
      panNumber,
      // onClickClientName,
      showViewMore,
      onClickViewMore,
      customRow,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/adminDashboard.scss';
</style>
